<template>
  <ul :class="$style.pagination">
    <li>
      <button
        :disabled="this.currentPage === 1"
        type="button"
        @click="onPageChanged('start')"
      >
        &#171;
      </button>
    </li>
    <li>
      <button
        :disabled="this.currentPage <= 5"
        type="button"
        @click="onPageChanged('prev')"
      >
        &#60;
      </button>
    </li>
    <li v-for="(num, idx) in pages" :key="idx">
      <button
        :class="currentPage === num ? $style['active'] : ''"
        type="button"
        @click="pageNumClick(num)"
      >
        {{ num }}
      </button>
    </li>
    <li>
      <button
        :disabled="Math.floor(this.pageSize / 5) * 5 + 1 <= currentPage"
        type="button"
        @click="onPageChanged('next')"
      >
        &#62;
      </button>
    </li>
    <li>
      <button
        :disabled="currentPage === pageSize"
        type="button"
        @click="onPageChanged('end')"
      >
        &#187;
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pages: [],
    };
  },
  watch: {
    pageSize(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.createPagination(1);
      }
    },
  },
  mounted() {
    this.createPagination(1);
  },
  methods: {
    createPagination(startNum) {
      let pages = [];

      for (let i = startNum; i <= startNum + 4; i++) {
        if (i <= this.pageSize) {
          pages.push(i);
        }
      }
      this.pages = pages;
    },
    onPageChanged(type) {
      let startNum, lastNum, currentNum;
      startNum = this.pages[0];
      lastNum = this.pages[this.pages.length - 1];

      switch (true) {
        case type === 'next' && this.pageSize <= lastNum:
        case type === 'prev' && startNum <= 1:
          return;
        case type === 'next' && this.pageSize >= lastNum:
          startNum = this.pages[0] + 5;
          currentNum = startNum;
          break;
        case type === 'prev' && startNum >= 1:
          startNum = this.pages[0] - 5;
          currentNum = startNum + 5 - 1;
          break;
        case type === 'start':
          startNum = 1;
          currentNum = startNum;
          break;
        case type === 'end':
          startNum = Math.floor(this.pageSize / 5) * 5 + 1;
          currentNum = this.pageSize;
          break;
        default:
          return;
      }
      this.$emit('pageNum', currentNum);
      this.createPagination(startNum);
    },
    pageNumClick(num) {
      this.$emit('pageNum', num);
    },
  },
};
</script>

<style module>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0.75rem 0;
}

.pagination > li > button {
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  background-color: #fff;
}
.pagination > li > button:disabled {
  color: #ddd;
}
.pagination > li > button.active {
  background-color: #4679cc;
  border: 1px solid #4679cc;

  color: #fff;
}
</style>
