import router from '@/router';
import { requestApi } from '@/api';

const authStore = {
  namespaced: true,
  state: {
    user: sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '',
    token: sessionStorage.getItem('auth') || '',
  },
  getters: {
    isLogin(state) {
      return state.token !== '';
    },
  },
  mutations: {
    SET_LOGIN_DATA(state, { login, token }) {
      state.user = login;
      state.token = token;
      sessionStorage.setItem('user', login);
      sessionStorage.setItem('auth', token);
    },
    async REMOVE_LOGIN_DATA(state) {
      state.user = '';
      state.token = '';
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('auth');
      await router.push('/login');
    },
  },
  actions: {
    async LOGIN({ commit }, { id, pwd }) {
      let token;

      try {
        const { data } = await requestApi.post('/rest/public/auth/login', {
          login: id,
          password: pwd,
        });

        switch (true) {
          case data.status === 'OK':
            token = await requestApi.post('/rest/public/jwt/login', {
              login: id,
              password: pwd,
            });
            commit('SET_LOGIN_DATA', {
              login: data.data.login,
              token: token.data?.id_token,
            });
            await router.push('/device');
            break;
          case data.status === 'ERROR':
            alert('아이디 또는 비밀번호가 일치하지 않습니다.');
            break;
          default:
            alert('Error: 로그인에 실패하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 로그인에 실패하였습니다.');
      }
    },
  },
};

export default authStore;
