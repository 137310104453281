import { requestApi } from '@/api';
import axios from 'axios';

const DATA = () => {
  return {
    deviceList: [],
    searchIpt: '',
    currentPageNum: 1,
    pageSize: 1,
    modal: {
      addDeviceModal: false,
    },
  };
};

const deviceStore = {
  namespaced: true,
  state: DATA(),
  getters: {},
  mutations: {
    SET_INIT_STATE(state) {
      for (let key in DATA()) {
        state[key] = DATA()[key];
      }
    },
    SET_CHANGE_VALUE(state, { name, value }) {
      state[name] = value;
    },
    SET_MODAL_OPEN(state, { name }) {
      state.modal[name] = !state.modal[name];
    },
    SET_DEVICE_LIST(state, { list, pageSize }) {
      state.deviceList = list;
      state.pageSize = pageSize;
    },
  },
  actions: {
    // 디바이스 목록 가져오기
    async GET_DEVICE_LIST({ state, commit }) {
      let list, pageSize;
      try {
        const { data } = await requestApi.post('/rest/private/devices/search', {
          value: state.searchIpt,
          groupId: -1,
          configurationId: -1,
          pageSize: 10,
          pageNum: state.currentPageNum,
          sortBy: null,
          sortDir: 'DESC',
        });
        switch (true) {
          case data.status === 'OK':
            list = data.data.devices.items;
            pageSize = Math.ceil(data.data.devices.totalItemsCount / 10);
            commit('SET_DEVICE_LIST', { list, pageSize });
            break;
          case data.status === 'ERROR':
            alert('디바이스 리스트를 불러오는데 실패하였습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
    // 디바이스 목록 추가
    async ADD_DEVICE_LIST({ dispatch, commit }, reqData) {
      const { configurationId, description, number } = reqData;
      try {
        const { data } = await axios.put(`/rest/private/devices`, {
          configurationId: configurationId,
          description: description,
          groups: [],
          number: number,
        });
        switch (true) {
          case data.status === 'OK':
            dispatch('GET_DEVICE_LIST', { pageNum: 1, value: '' });
            commit('SET_MODAL_OPEN', { name: 'addDeviceModal' });
            break;
          case data.status === 'ERROR':
            alert('정보 추가에 실패했습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
    // 디바이스 목록 수정
    async EDIT_DEVICE_LIST({ dispatch }, detailInfo) {
      const { configurationId, description, id, number } = detailInfo;
      try {
        const { data } = await requestApi.put(`/rest/private/devices`, {
          configurationId: configurationId,
          description: description,
          id: id,
          number: number,
        });
        switch (true) {
          case data.status === 'OK':
            alert('정보 수정이 완료되었습니다.');
            dispatch('GET_DEVICE_LIST');
            return data.status;
          case data.status === 'ERROR':
            alert('정보 수정에 실패했습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },

    // 디바이스 목록 삭제
    async DELETE_DEVICE_LIST({ dispatch }, deviceId) {
      try {
        const { data } = await requestApi.delete(
          `/rest/private/devices/${deviceId}`,
        );
        switch (true) {
          case data.status === 'OK':
            dispatch('GET_DEVICE_LIST');
            break;
          case data.status === 'ERROR':
            alert('장치 삭제에 실패했습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
    // 구성 그룹 정보 가져오기
    async GET_CONFIGURATION_INFO() {
      try {
        const { data } = await requestApi.get(
          './rest/private/configurations/search',
        );
        switch (true) {
          case data.status === 'OK':
            // this.deviceList = data.data.devices.items;
            break;
          case data.status === 'ERROR':
            alert('디바이스 리스트를 불러오는데 실패하였습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
  },
};

export default deviceStore;
