<template>
  <nav :class="$style.nav">
    <ul>
      <li
        v-for="item in tabs"
        :key="item.id"
        :class="[$style.list, $route.path === item.path && $style.active]"
      >
        <router-link :to="item.path">
          <FontAwesomeIcon :class="$style.icon" :icon="['fas', item.icon]" />
          {{ item.title }}</router-link
        >
      </li>
      <li :class="$style.list">
        <a @click="openQRPage">
          <FontAwesomeIcon :class="$style.icon" :icon="['fas', 'qrcode']" />
          QR</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: 'Device',
          path: '/device',
          icon: 'mobile-screen-button',
        },
        {
          id: 1,
          title: 'Configurations',
          path: '/configurations',
          icon: 'gear',
        },
        // { id: 2, title: 'QR', path: '/qr', icon: 'qrcode' },
      ],
    };
  },
  methods: {
    openQRPage() {
      const protocol = window.location.protocol;
      const hostName = window.location.hostname;
      const port = window.location.port;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;

      window.open(
        `${protocol}//${hostName}:${port}/qr`,
        'childWindow',
        `width=${screenWidth},height=${screenHeight}`,
      );
    },
  },
};
</script>

<style module>
.nav {
  width: 210px;
  padding: 1.333rem 0;
  background-color: #335289;
}

.list {
  color: #a4bbe6;
  font-size: 1.083rem;
  transition: all 0.25s ease-in-out;
}

.list > a {
  display: block;
  padding: 1.25rem 1.667rem;
}

.list:hover {
  background-color: #294470;
}

.list.active {
  color: #fff;
  background-color: #284b7f;
}

.icon {
  margin-right: 0.667rem;
}
</style>
