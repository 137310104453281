<template>
  <button
    :class="[$style.btn, $style[color], $style[size]]"
    :type="type"
    :disabled="disabled"
    @click="$emit('onClick')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
      validator: function (value) {
        return ['button', 'submit'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Style
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'warning', 'tertiary'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'sm',
      validator: function (value) {
        return ['lg', 'sm', 'xs'].includes(value);
      },
    },
  },
};
</script>

<style module>
.btn {
  display: block;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.btn:disabled {
  background-color: #e9ecef;
  border: 1px solid #e5e5e5;
  color: #bdbdbd;
  cursor: no-drop;
}

.lg {
  width: 100%;
}

.sm {
  min-width: 68px;
}

.primary {
  background-color: #4679cc;
  border: 1px solid #4679cc;
  color: #fff;
}

.warning {
  background-color: #dd5e56;
  border: 1px solid #dd5e56;
  color: #fff;
}

.primary:hover,
.warning:hover {
  opacity: 0.9;
}

.tertiary {
  background-color: #fff;
  border: 1px solid #ccc;
  color: inherit;
}

.tertiary:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}
</style>
