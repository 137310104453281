<template>
  <input
    :name="name"
    :class="[$style.ipt, $style[size]]"
    :style="cssProps"
    @input="$emit('onInput', $event)"
    :value="value"
    :type="type"
    :required="required"
    :placeholder="placeholder"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Style
    width: {
      type: String,
      default: '100%',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  computed: {
    cssProps() {
      return {
        '--width': this.width,
        '--height': this.height,
      };
    },
  },
};
</script>

<style module>
.ipt {
  width: var(--width);
  padding: 0.857rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.ipt:disabled {
  background-color: #e9ecef;
}

.ipt.lg {
  padding: 0.857rem;
}

.ipt.sm {
  padding: 0.571rem 0.857rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
</style>
