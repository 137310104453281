<template>
  <header :class="$style.header">
    <h1 :class="$style.title">MDM</h1>
    <ul :class="$style.user">
      <li>{{ user }}</li>
      <li>
        <button type="button" :class="$style.logoutBtn" @click="logout">
          로그아웃
        </button>
      </li>
    </ul>
  </header>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapMutations('auth', ['REMOVE_LOGIN_DATA']),
    logout() {
      if (confirm('로그아웃 하시겠습니까?')) {
        this.REMOVE_LOGIN_DATA();
      }
    },
  },
};
</script>

<style module>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a4471;
  padding: 1rem 2rem;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #d0e3eb;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  color: #fff;
  font-size: 1rem;
  margin-right: 1rem;
}

.logoutBtn {
  padding: 0.25rem 0.5rem;
  border: 1px solid #fff;
  border-radius: 4px;
  line-height: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.logoutBtn:hover {
  border: 1px solid #e6e6e6;
  background-color: #e6e6e6;
  color: #333;
}
</style>
