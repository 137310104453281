import axios from 'axios';
import { setInterceptors } from '@/api/interceptors';

function createInstance() {
  const instance = axios.create({
    // baseURL: process.env.VUE_APP_API_URL || `https://mdm.tilon.com`,
  });
  return setInterceptors(instance);
}

export const requestApi = createInstance();
