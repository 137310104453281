import Vue from 'vue';

// common
import TheHeader from '@/components/common/TheHeader.vue';
import TheFooter from '@/components/common/TheFooter.vue';
import TheContainer from '@/components/common/TheContainer.vue';
import TheNavigation from '@/components/common/TheNavigation.vue';
import ThePagination from '@/components/common/ThePagination.vue';
import TheModal from '@/components/common/TheModal.vue';

Vue.component('TheHeader', TheHeader);
Vue.component('TheContainer', TheContainer);
Vue.component('TheNavigation', TheNavigation);
Vue.component('ThePagination', ThePagination);
Vue.component('TheFooter', TheFooter);
Vue.component('TheModal', TheModal);

// element
import TheInput from '@/components/elements/TheInput.vue';
import TheTable from '@/components/elements/TheTable.vue';
import TheButton from '@/components/elements/TheButton.vue';
import TheTextarea from '@/components/elements/TheTextarea.vue';
import TheSelectBox from '@/components/elements/TheSelectBox.vue';

Vue.component('TheInput', TheInput);
Vue.component('TheTextarea', TheTextarea);
Vue.component('TheTable', TheTable);
Vue.component('TheButton', TheButton);
Vue.component('TheSelectBox', TheSelectBox);
