import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: () => import('@/pages/Index.vue'),
      children: [
        {
          path: '/device',
          component: () => import('@/pages/DevicePage.vue'),
        },
        {
          path: '/configurations',
          component: () => import('@/pages/ConfigurationPage.vue'),
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/pages/LoginPage.vue'),
    },
    {
      path: '/qr',
      component: () => import('@/pages/QRPage.vue'),
    },
  ],
});

export default router;
