<template>
  <div :class="$style.container">
    <table :class="$style.table">
      <colgroup>
        <col
          v-for="(col, idx) in options?.colgroup"
          :style="{ width: col }"
          :key="idx"
        />
      </colgroup>
      <thead :class="$style.thead">
        <tr>
          <th v-for="(th, idx) in options?.thead" :key="idx">
            {{ th }}
          </th>
        </tr>
      </thead>
      <tbody :class="$style.tbody">
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    options: Object,
  },
};
</script>

<style module>
.container {
  width: 100%;
}
.table {
  width: 100%;
  font-size: 10px;
}
.thead {
  border-bottom: 2px solid #4f7ac8;
}

.thead > tr > th {
  font-weight: 600;
  padding-bottom: 0.714rem;
}

.tbody {
  text-align: center;
}

.tbody > tr {
  border-bottom: 1px solid #bdbdbd;
}
.tbody > tr:hover {
  background-color: #e9f0fd;
}

.tbody > tr > td {
  padding: 1rem 0.25rem;
}
</style>
