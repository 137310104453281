import Vue from 'vue';
import Vuex from 'vuex';

// modules
import authStore from '@/store/modules/authStore';
import deviceStore from '@/store/modules/deviceStore';
import configurationStore from '@/store/modules/configurationStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authStore,
    device: deviceStore,
    configuration: configurationStore,
  },
});
