<template>
  <TheModal title="장치 추가">
    <form @submit.prevent="addDeviceList">
      <ul :class="$style.list">
        <li>
          <strong>Number</strong>
          <TheInput
            name="number"
            @onInput="changeValue($event)"
            :value="reqData.number"
            size="sm"
            required
          />
        </li>
        <li>
          <strong>Description</strong>
          <TheTextarea
            :value="reqData.description"
            @onInput="changeValue($event)"
            name="description"
            required
          />
        </li>
        <li>
          <strong>Configuration</strong>
          <TheSelectBox disabled :value="reqData.configurationId">
            <option value="1">Common-Minimal</option>
          </TheSelectBox>
        </li>
      </ul>
      <div :class="$style.btnGroup">
        <TheButton type="submit">추가</TheButton>
        <TheButton
          color="warning"
          @onClick="SET_MODAL_OPEN({ name: 'addDeviceModal' })"
          >닫기</TheButton
        >
      </div>
    </form>
  </TheModal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      reqData: {
        configurationId: 1,
        description: '',
        number: '',
      },
    };
  },
  methods: {
    ...mapActions('device', ['ADD_DEVICE_LIST']),
    ...mapMutations('device', ['SET_MODAL_OPEN', 'SET_DEVICE_LIST']),
    changeValue(e) {
      const name = e.target.name;
      this.reqData[name] = e.target.value;
    },
    async addDeviceList() {
      await this.ADD_DEVICE_LIST(this.reqData);
    },
  },
};
</script>

<style module>
.list > li:not(:last-child) {
  margin-bottom: 1rem;
}

.list > li > strong {
  display: block;
  font-weight: 500;
  padding-left: 2px;
  margin-bottom: 0.5rem;
}

.btnGroup {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
  margin: 2rem 0 1rem;
}
</style>
