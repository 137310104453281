<template>
  <div :class="$style.wrap">
    <div :class="$style.modal">
      <h3 :class="$style.tit">{{ title }}</h3>
      <div :class="$style.content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style module>
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(24, 24, 24, 0.2);
  z-index: 100;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 500px;*/
  min-width: 500px;
  /*min-height: 460px;*/
  /*padding: 1rem;*/
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0 30px 60px -30px;
  overflow: hidden;
  z-index: 110;
}

.tit {
  display: block;
  width: 100%;
  font-size: 1.167rem;
  padding: 0.833rem 0;
  color: #495057;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: #f7f7f7;
}

.content {
  padding: 1.5rem 2rem;
}
</style>
