<template>
  <div :class="$style.container">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style module>
.container {
  flex: 1;
  padding: 2.083rem 1.75rem;
}
</style>
