<template>
  <footer :class="$style.footer">MDM</footer>
</template>

<script>
export default {};
</script>

<style module>
.footer {
  background-color: #f7f7f7;
  text-align: center;
  padding: 0.5rem;
}
</style>
