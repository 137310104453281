<template>
  <textarea
    :class="$style.textarea"
    :name="name"
    @input="$emit('onInput', $event)"
    :value="value"
    :required="required"
    :placeholder="placeholder"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Style
    size: {
      type: String,
      default: 'lg',
    },
  },
};
</script>

<style module>
.textarea {
  width: 100%;
  min-height: 120px;
  padding: 0.5rem 0.857rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  resize: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.textarea:disabled {
  background-color: #e9ecef;
}
</style>
