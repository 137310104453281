import { requestApi } from '@/api';

const configurationStore = {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async GET_CONFIGURATION_DATA() {
      try {
        const { data } = await requestApi.get('/rest/private/configurations/1');
        switch (true) {
          case data.status === 'OK':
            return data.data;
          case data.status === 'ERROR':
            alert('Configurations 리스트를 불러오는데 실패하였습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
    async POST_CONFIGURATION_DATA(_, iptData) {
      try {
        const { data } = await requestApi.put(
          '/rest/private/configurations',
          iptData,
        );
        switch (true) {
          case data.status === 'OK':
            alert('Configurations 저장에 성공하였습니다.');
            break;
          case data.status === 'ERROR':
            alert('Configurations 저장에 실패하였습니다.');
            break;
          default:
            alert('Error: 오류가 발생하였습니다.');
            break;
        }
      } catch (e) {
        console.error(e);
        alert('Error: 오류가 발생하였습니다.');
      }
    },
  },
};

export default configurationStore;
